import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FeaturedSite from './FeaturedSite'

function FeaturedSiteList() {
	const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }
	var featuredsitelist = window.Configs.featuredsites;
	featuredsitelist.map((key, index)=>featuredsitelist[index]['id'] = index);
	const featuredSiteList = featuredsitelist.map(site => <FeaturedSite key={site.id} id={site.id} site={site} />)

	return (
		<div className="main-content-owlcarousel">
			<OwlCarousel items={3} className="owl-theme" loop margin={15} navresponsive={state.responsive}>
				{featuredSiteList}
			</OwlCarousel>
		</div>
	)
}

export default FeaturedSiteList
