import React, { Component } from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';
import axios from 'axios'
import FeaturedSiteList from './components/FeaturedSiteList';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Logo from './components/Logo';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "offer",
      offer_amount: 0,
      cost_per_month: 0,
      full_price: 0,
      price: 0,
      partner_type: "Sponsorship Marketing Partnerships",
    };
  }
  componentDidMount() {
    const url =
      "https://domaindirectory.com/lease/domaininfo?domain=" +
      window.Configs.domain;
    console.log("url", url);
    axios.get(url).then((response) => {
      this.setState({ cost_per_month: response.data.cost_per_month });
      this.setState({ price: response.data.price });
      this.setState({ offer_amount: response.data.price });
      this.setState({ full_price: response.data.price });
    });

    console.log("window.Configs.domain", window.Configs.domain);
    console.log("window.Configs.domainid", window.Configs.domainid);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  offerChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <script type='text/javascript'>
            {`
            $(document).ready(function(){
                $('.buy-options-check').click(function(){
                    $('.buy-options-check').removeClass('active');
                    $('.buy-option-item').removeClass('active');
                    $(this).addClass('active');
                    if($(this).hasClass('lease-checkbox')){
                        $(this).parents('.buy-option-item').addClass('active');
                    }
                })
            });
            `}
          </script>
        </Helmet>
        <style>
          {`
						.section-2{
							padding-top: 50px;
						}
					`}
        </style>
        <div
          className='buy-feature-section'
          style={{
            backgroundImage: `url(${window.Configs.background_image})`,
          }}
        >
          <div className='overlay'></div>
          <Container>
            <Row>
              <Col lg='8' className=''>
                <div className='mb-3'>
                  <Logo />
                </div>
                <p>
                  <a
                    className=''
                    href='https://contrib.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Proud Member of CONTRIB
                  </a>{" "}
                  |
                  <a
                    className=''
                    href='https://vnoc.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Powered by VNOC
                  </a>
                </p>
              </Col>
              <Col lg='4' className=''>
                <div className='buy-form'>
                  <div className='buy-form-header'>
                    <h4 className='title-buy-form font-open-sans'>
                      Get this domain
                    </h4>
                    <p className='fnt-400 mb-0'>
                      {/* Pay the full USD ${this.state.full_price} now, or make an offer. */}
                    </p>
                  </div>
                  <div className='buy-form-body'>
                    <div className='buy-options'>
                      <div className='buy-option-item active'>
                        <label
                          className='form-check-label buy-options-check lease-checkbox active'
                          htmlFor='optionsBuy'
                        >
                          <input
                            checked={this.state.type === "offer"}
                            className='form-check-input'
                            type='radio'
                            onChange={this.handleChange}
                            name='type'
                            id='optionsBuy'
                            value='offer'
                          />
                          Make an offer
                          {/* Start:: Minimum Price in DB */}
                          <span className='buy-form-amount ml-auto text-primary'>
                            USD ${this.state.offer_amount}
                          </span>
                        </label>
                        <div className='buy-lease-offer-input'>
                          <input
                            onKeyPress={(event) => {
                              if (!/^\d*\.?\d*$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={this.offerChange}
                            name='offer_amount'
                            type='number'
                            value={this.state.offer_amount}
                            className='form-control'
                            placeholder='My offer in USD'
                          />
                        </div>
                      </div>
                      <div className='buy-option-item'>
                        <label
                          className='form-check-label buy-options-check lease-checkbox'
                          htmlFor='optionsPartner'
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            onChange={this.handleChange}
                            name='type'
                            id='optionsPartner'
                            value='partner'
                          />
                          Partner
                        </label>
                        <div className='buy-lease-offer-input'>
                          <select
                            name='partner_type'
                            className='custom-select'
                            onChange={this.handleChange}
                            value={this.state.partner_type}
                          >
                            <option value='' disabled defaultValue>
                              Type of Partnership
                            </option>
                            <option value='Sponsorship Marketing Partnerships'>
                              Sponsor Marketing Partnerships
                            </option>
                            <option value='Distribution Marketing Partnerships'>
                              Distribution Marketing Partnerships
                            </option>
                            <option value='Affiliate Marketing Partnerships'>
                              Affiliate Marketing Partnerships
                            </option>
                            <option value='Added Value Marketing Partnerships'>
                              Added Value Marketing Partnerships
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className='buy-option-item'>
                        <label
                          className='form-check-label buy-options-check '
                          htmlFor='optionsJoin'
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            onChange={this.handleChange}
                            name='type'
                            id='optionsJoin'
                            value='join'
                          />
                          Join Team
                        </label>
                      </div>
                      {this.state.cost_per_month > 0 ? (
                        <div className='buy-option-item'>
                          <label
                            className='form-check-label buy-options-check '
                            htmlFor='optionsRent'
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              onChange={this.handleChange}
                              name='type'
                              id='optionsRent'
                              value='lease'
                            />
                            Rent
                            {/* Start:: Minimum Price in DB */}
                            <span className='buy-form-amount ml-auto text-primary'>
                              USD $ {this.state.cost_per_month}
                              <span className='text-muted fnt-400'>/month</span>
                            </span>
                          </label>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className='buy-form-button'>
                      <Link
                        id='buy'
                        to={"/buy?offer=" + this.state.offer_amount}
                        className={
                          this.state.type === "offer"
                            ? "btn btn-primary btn-block py-3"
                            : "d-none"
                        }
                      >
                        Next
                        <svg
                          className='w-6 h-6 ml-2'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M14 5l7 7m0 0l-7 7m7-7H3'
                          />
                        </svg>
                      </Link>
                      <Link
                        id='partner'
                        to={"/partner?type=" + this.state.partner_type}
                        className={
                          this.state.type === "partner"
                            ? "btn btn-primary btn-block py-3"
                            : "d-none"
                        }
                      >
                        Next
                        <svg
                          className='w-6 h-6 ml-2'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M14 5l7 7m0 0l-7 7m7-7H3'
                          />
                        </svg>
                      </Link>
                      <a
                        id='join'
                        href={
                          "https://www.contrib.com/signup/firststep?domain=" +
                          window.Configs.domain
                        }
                        className={
                          this.state.type === "join"
                            ? "btn btn-primary btn-block py-3"
                            : "d-none"
                        }
                      >
                        Next
                        <svg
                          className='w-6 h-6 ml-2'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M14 5l7 7m0 0l-7 7m7-7H3'
                          />
                        </svg>
                      </a>
                      <a
                        id='lease'
                        href={
                          "https://domaindirectory.com/lease/" +
                          window.Configs.domain
                        }
                        className={
                          this.state.type === "lease"
                            ? "btn btn-primary btn-block py-3"
                            : "d-none"
                        }
                      >
                        Next
                        <svg
                          className='w-6 h-6 ml-2'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M14 5l7 7m0 0l-7 7m7-7H3'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className='buy-form-footer'>
                    <ul className='list-inline mb-0 text-center'>
                      <li className='list-inline-item'>
                        <div className='box-mode-pay'>
                          <img
                            height='30'
                            width='80'
                            src='https://cdn.vnoc.com/icons/frameworks/paypal.svg'
                            alt=''
                          />
                        </div>
                      </li>
                      <li className='list-inline-item'>
                        <div className='box-mode-pay'>
                          <img
                            height='30'
                            width='80'
                            src='https://cdn.vnoc.com/icons/frameworks/bitcoin-sv-1.svg'
                            alt=''
                          />
                        </div>
                      </li>
                      <li className='list-inline-item'>
                        <div className='box-mode-pay'>
                          <img
                            height='30'
                            width='80'
                            src='https://cdn.vnoc.com/icons/frameworks/binance-coin-bnb.svg'
                            alt=''
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='section-2'>
          <Container>
            <Row>
              <Col lg={8}>
                <p>
                  Your site is a reflection of your brand, invest in an
                  impactful domain name like{" "}
                  {window.Configs.domain.charAt(0).toUpperCase() +
                    window.Configs.domain.slice(1)}{" "}
                  that projects your voice. You can make an offer, partner,
                  lease or join the{" "}
                  {window.Configs.domain.charAt(0).toUpperCase() +
                    window.Configs.domain.slice(1)}{" "}
                  team today at Contrib.
                </p>
                <p>
                  Our Contrib community has 150,000+ contributors who share
                  their original content for{" "}
                  {window.Configs.domain.charAt(0).toUpperCase() +
                    window.Configs.domain.slice(1)}
                  .
                </p>
                <Row className='pt-lg-3'>
                  <Col lg={4} className='text-center mb-3'>
                    <img
                      width='80'
                      className='img-fluid how-icon'
                      src='https://cdn.vnoc.com/icons/icon-diamond.png'
                      alt='Make an offer'
                    />
                    <h4>Make An Offer</h4>
                    <p className='small'>
                      Find a domain and decide to buy,lease or rent it. Submit
                      your best offer for the owner's consideration.
                    </p>
                  </Col>
                  <Col lg={4} className='text-center mb-3'>
                    <img
                      width='80'
                      className='img-fluid how-icon'
                      src='https://cdn.vnoc.com/icons/icon-briefcase.png'
                      alt='Negotiate the price'
                    />
                    <h4>Negotiate the price</h4>
                    <p className='small'>
                      Your offer may be accepted, countered or declined.
                      Negotiations can take as little as one day or up to a few
                      weeks.
                    </p>
                  </Col>
                  <Col lg={4} className='text-center mb-3'>
                    <img
                      width='80'
                      className='img-fluid how-icon'
                      src='https://cdn.vnoc.com/icons/icon-handshake.png'
                      alt='Make a deal'
                    />
                    <h4>Make a deal</h4>
                    <p className='small'>
                      Once you have agreed on the price, We will facilitate the
                      contract, payment and transfer or use of the domain name.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='section-3'>
          <Container>
            <Row>
              <Col lg={12}>
                <h2 className='text-center mb-5'>
                  {window.Configs.domain.charAt(0).toUpperCase() +
                    window.Configs.domain.slice(1)}{" "}
                  Partners
                </h2>
                <Row>
                  <Col lg={4} className='mb-3'></Col>
                  <Col lg={4} className='mb-3'></Col>
                  <Col lg={4} className='mb-3'>
                    <Card>
                      <Card.Header>Latest Contribution</Card.Header>
                      <Card.Body className='card-overflow-cus'>
                        <ul className='list-unstyled'>
                          <li>
                            <div className='media'>
                              <img
                                src='https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/uploads/profile/1586292067fPV09.jpg&w=115&h=115'
                                className='mr-3 img-thumbnail-cust'
                                alt=''
                              />
                              <div className='media-body'>
                                <h5 className='mt-0'>Techy Micro</h5>
                                <p className='small mb-0'>
                                  Has contributed marketing on &nbsp;{" "}
                                  <a href='/'>staffingnetworks.com</a>
                                </p>
                                <p className='text-danger fnt-600'>20 pts</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='media'>
                              <img
                                src='https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115'
                                className='mr-3 img-thumbnail-cust'
                                alt=''
                              />
                              <div className='media-body'>
                                <h5 className='mt-0'>Techy Micro</h5>
                                <p className='small mb-0'>
                                  Has contributed marketing on &nbsp;{" "}
                                  <a href='/'>staffingnetworks.com</a>
                                </p>
                                <p className='text-danger fnt-600'>20 pts</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='media'>
                              <img
                                src='https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115'
                                className='mr-3 img-thumbnail-cust'
                                alt=''
                              />
                              <div className='media-body'>
                                <h5 className='mt-0'>Techy Micro</h5>
                                <p className='small mb-0'>
                                  Has contributed marketing on &nbsp;{" "}
                                  <a href='/'>staffingnetworks.com</a>
                                </p>
                                <p className='text-danger fnt-600'>20 pts</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='media'>
                              <img
                                src='https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115'
                                className='mr-3 img-thumbnail-cust'
                                alt=''
                              />
                              <div className='media-body'>
                                <h5 className='mt-0'>Techy Micro</h5>
                                <p className='small mb-0'>
                                  Has contributed marketing on &nbsp;{" "}
                                  <a href='/'>staffingnetworks.com</a>
                                </p>
                                <p className='text-danger fnt-600'>20 pts</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='media'>
                              <img
                                src='https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115'
                                className='mr-3 img-thumbnail-cust'
                                alt=''
                              />
                              <div className='media-body'>
                                <h5 className='mt-0'>Techy Micro</h5>
                                <p className='small mb-0'>
                                  Has contributed marketing on &nbsp;{" "}
                                  <a href='/'>staffingnetworks.com</a>
                                </p>
                                <p className='text-danger fnt-600'>20 pts</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='media'>
                              <img
                                src='https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115'
                                className='mr-3 img-thumbnail-cust'
                                alt=''
                              />
                              <div className='media-body'>
                                <h5 className='mt-0'>Techy Micro</h5>
                                <p className='small mb-0'>
                                  Has contributed marketing on &nbsp;{" "}
                                  <a href='/'>staffingnetworks.com</a>
                                </p>
                                <p className='text-danger fnt-600'>20 pts</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='media'>
                              <img
                                src='https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115'
                                className='mr-3 img-thumbnail-cust'
                                alt=''
                              />
                              <div className='media-body'>
                                <h5 className='mt-0'>Techy Micro</h5>
                                <p className='small mb-0'>
                                  Has contributed marketing on &nbsp;{" "}
                                  <a href='/'>staffingnetworks.com</a>
                                </p>
                                <p className='text-danger fnt-600'>20 pts</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='section-4'>
          <Container>
            <Row>
              <Col lg={12}>
                <h1 className='text-center fnt-700'>OUR TOP BRANDS</h1>
                <FeaturedSiteList />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;