import React, { Component } from 'react';
import { Container, Row, Col, Image, Form} from 'react-bootstrap';

import axios from 'axios'

class Buy2 extends Component {
  state = {  } 
  constructor(props) {
		super(props)

		this.state = {
			buyLease:0,
			submit:false,
			countries:[],
			tab:-1,
			firstname:'',
			lastname:'',
			city:'',
			country:'Afghanistan',
			email:'',
			address:'',
			billing_country_rep:"1",
			billing_telegram:'',
			billing_linkedin:'',
			billing_discord:'',
			billing_twitter:'',
			social:'Telegram',
			btnSubmitText:'Submit',
			success:false,
			domain:window.Configs.domain,
			num_months:0,
			cost_per_month:0,
		}
	}
	componentDidMount() {
		const url = 'https://domaindirectory.com/lease/domaininfo?domain='+window.Configs.domain;
		console.log('url',url);
		axios.get(url)
		.then(response => {
			this.setState({num_months : response.data.num_months});
			this.setState({cost_per_month : response.data.cost_per_month});
		});
		axios.get('https://domaindirectory.com/lease/countries')
		.then(response => {
			this.setState({countries : response.data});
		});
		
		console.log('window.Configs.domain',window.Configs.domain);
		console.log('window.Configs.domainid',window.Configs.domainid);
	}
	
	handleChange = event => {
		this.setState({
			[event.target.name] : event.target.value
		});
	};
	
	setTab = event =>{
		event.preventDefault();
		this.setState({ submit: true }); 
		
		// if(this.validate())
			// this.setState({ tab: this.state.tab+1 }); 
	}
		
	isValid = (el) => {
		var pattern = new RegExp('^((ft|htt)ps?:\\/\\/)?'+ // protocol
			  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
			  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			  '(\\:\\d+)?'+ // port
			  '(\\/[-a-z\\d%@_.~+&:]*)*'+ // path
			  '(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // query string
			  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
			  
		if(el==='firstname' || el==='lastname' || el==='address' || el==='city'){
			return this.state[el]!=='';
		}else if(el==='email'){
			return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state[el]);
		}else if(el==='social' && this.state.social==='Telegram'){
			return pattern.test(this.state.billing_telegram);
		}else if(el==='social' && this.state.social==='Linkedin'){
			return pattern.test(this.state.billing_linkedin);
		}else if(el==='social' && this.state.social==='Discord'){
			return pattern.test(this.state.billing_discord);
		}else if(el==='social' && this.state.social==='Twitter'){
			return pattern.test(this.state.billing_twitter);
		}else{
			return false;
		}
	}
	
	validate = () => {
		var pattern = new RegExp('^((ft|htt)ps?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?'+ // port
      '(\\/[-a-z\\d%@_.~+&:]*)*'+ // path
      '(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

		if(!this.state.firstname || !this.state.lastname || !this.state.address || !this.state.city ){
			console.log('required firstname, lastname, address, city');
			return false;
	    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)){
			console.log('invalid email');
			return false;
	    }else if(this.state.social==='Telegram' && !pattern.test(this.state.billing_telegram)){
			console.log('invalid social url billing_telegram');
			return false;
		}else if(this.state.social==='Linkedin' && !pattern.test(this.state.billing_linkedin)){
			console.log('invalid social url billing_telegram');
			return false;
		}else if(this.state.social==='Discord' && !pattern.test(this.state.billing_discord)){
			console.log('invalid social url billing_discord');
			return false;
		}else if(this.state.social==='Twitter' && !pattern.test(this.state.billing_twitter)){
			console.log('invalid social url billing_twitter');
			return false;
	    }
		return true;
	};
	
	handleSubmit = event => {
		event.preventDefault();

		const isValid = this.validate();

		if (isValid) {
			const params = new URLSearchParams();
			params.append('billing_name', this.state.firstname+' '+this.state.lastname);
			params.append('billing_email', this.state.email);
			params.append('billing_address', this.state.address);
			params.append('billing_city', this.state.city);
			params.append('billing_country', this.state.country);
			params.append('billing_telegram', this.state.billing_telegram);
			params.append('billing_linkedin', this.state.billing_linkedin);
			params.append('billing_discord', this.state.billing_discord);
			params.append('billing_twitter', this.state.billing_twitter);
			params.append('billing_country_rep', this.state.billing_country_rep);
			params.append('billing_domain_id', window.Configs.domainid);
			params.append('billing_domain', window.Configs.domain);
			params.append('submission_id', '');
			
			this.setState({btnSubmitText : "Please Wait.."});
			axios.post('https://domaindirectory.com/payment/savelease', params)
				.then(response => {
					this.setState({btnSubmitText : "Submit"});
					if(response.data.success){
						this.setState({success :true});
						this.setState({btnSubmitText : "Redirecting to Domaindirectory.com"});
						window.location = "https://domaindirectory.com/payment/confirm?id="+response.data.id;
					}else{
						alert('an error has occurred please try again later');
					}
				}).catch(error => {
					alert('an error has occurred please try again later');
					console.log(error.response)
				});
		}
	};
  render() { 
    return (
      <>
        {/* Start:: 1st */}
		    <div id="tab0" className={this.state.tab===-1 ? '' : 'd-none' } >
          <div className='buy-feature-section' style={{backgroundImage:"url('https://cdn.vnoc.com/background/tech4.jpg')"}}>
          <div class="overlay"></div>
            <Container>
              <Row>
                <Col lg="8" className=''>
                  <div className="title-light">
                    The domain name
                  </div>
                  <div>
                    <Image className='buy-logo-domain d-none' fluid src="https://cdn.vnoc.com/logos/logo-javapoint1.png" />
                    <div className="buy-domain-name">
                      javapoint.com
                    </div>
                  </div>
                  <div className="title-light mb-3">
                  is for sale!
                  </div>
                  <p>
                    <a class="" href="https://contrib.com/" target='_blank' rel="noopener noreferrer">Proud Member of CONTRIB</a> | 
                    <a class="" href="https://vnoc.com/" target='_blank' rel="noopener noreferrer">Powered by VNOC</a>
                  </p>
                </Col>
                <Col lg="4" className=''>
                  <div className='buy-form'>
                    <div className="buy-form-header">
                      <h4 className='title-buy-form font-open-sans'>
                      Get this domain
                      </h4>
                      <p className='fnt-400 mb-0'>
                      {/* Pay the full USD $2,188 now, or make an offer. */}
                      </p>
                    </div>
                    <div className="buy-form-body">
                      <div className="buy-options">
                        <div className="buy-option-item">
                          <label className="form-check-label buy-options-check lease-checkbox " for="optionsBuy">
                            <input className="form-check-input" type="radio" name="buyLease" id="optionsBuy" value="0"  />
                            Make an offer
                            {/* Start:: Minimum Price in DB */}
                            <span className='buy-form-amount ml-auto text-primary'>
                              USD $10,000
                            </span>
                          </label>
                          <div className="buy-lease-offer-input">
                            <input type="text" className="form-control" placeholder='My offer in USD'  />
                          </div>
                        </div>
                        <div className="buy-option-item">
                          <label className="form-check-label buy-options-check lease-checkbox" for="optionsPartner">
                            <input className="form-check-input" type="radio" name="buyLease" id="optionsPartner" value="0" />
                            Partner
                          </label>
                          <div className="buy-lease-offer-input">
                            <select className="custom-select">
                              <option value=""disabled selected>Type of Partnership</option>
                              <option value="Sponsorship Marketing Partnerships">Sponsor Marketing Partnerships</option>
                              <option value="Distribution Marketing Partnerships">Distribution Marketing Partnerships</option>
                              <option value="Affiliate Marketing Partnerships">Affiliate Marketing Partnerships</option>
                              <option value="Added Value Marketing Partnerships">Added Value Marketing Partnerships</option>
                            </select>
                          </div>
                        </div>
                        <div className="buy-option-item">
                          <label className="form-check-label buy-options-check " for="optionsJoin">
                            <input className="form-check-input" type="radio" name="buyLease" id="optionsJoin" value="0" />
                            Join Now
                          </label>
                        </div>
                        <div className="buy-option-item">
                          <label className="form-check-label buy-options-check " for="optionsRent">
                            <input className="form-check-input" type="radio"  name="buyLease" id="optionsRent" value="1" />
                            Rent
                            {/* Start:: Minimum Price in DB */}
                            <span className='buy-form-amount ml-auto text-primary'>
                              USD $50<span className='text-muted fnt-400'>/month</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="buy-form-button">
                        <button onClick={this.setTab} className="btn btn-primary btn-block py-3">
                          Next
                          <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" /></svg>
                        </button>
                      </div>
                    </div>
                    <div className="buy-form-footer">
                      <div className="text-center mb-1">
                      Secure payments
                      </div>
                      <ul className="mb-0 list-inline text-center text-muted">
                        <li className='list-inline-item mr-3'>
                          <i className="fab fa-ethereum"></i>
                        </li>
                        <li className='list-inline-item mr-3'>
                          <img height={'16'} src="https://cdn.vnoc.com/icons/realtydao/icons/binance-coin-bnb-logo.png" alt="" />
                        </li>
                        <li className='list-inline-item'>
                          <i className="fab fa-btc"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="buy-section-contents">
            <Container>
              <Row>
                <Col lg="8" className=''>
                  <Row>
                    <Col lg="6" className='mb-5 text-center'>
                      <img style={{height:'88px'}} className='img-fluid rounded-circle mb-3' src="https://cdn.vnoc.com/icons/domain.jpg" alt="" />
                      <h5>Select Your Domain</h5>
                      <p className='mb-0'>
                      Search Ecorp’s database for the ultimate domain name for your business, project or brand.
                      </p>
                    </Col>
                    <Col lg="6" className='mb-5 text-center'>
                      <img style={{height:'88px'}} className='img-fluid rounded-circle mb-3' src="https://cdn.vnoc.com/icons/offer.jpg" alt="" />
                      <h5>Submit your best offer</h5>
                      <p className='mb-0'>
                      We'll let you know if it's too low for consideration.
                      </p>
                    </Col>
                    <Col lg="6" className='mb-5 text-center'>
                      <img style={{height:'88px'}} className='img-fluid rounded-circle mb-3' src="https://cdn.vnoc.com/icons/agreement.jpg" alt="" />
                      <h5>Agree to the terms</h5>
                      <p className='mb-0'>
                      Once the price is decided, agree to the terms of the domain name sales agreement.
                      </p>
                    </Col>
                    <Col lg="6" className='mb-5 text-center'>
                      <img style={{height:'88px'}} className='img-fluid rounded-circle mb-3' src="https://cdn.vnoc.com/icons/transfer.jpg" alt="" />
                      <h5>Launch your website</h5>
                      <p className='mb-0'>
                      Once payment has been confirmed the owner will transfer the domain to you.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* End:: 1st */}
        {/* Start:: 2nd */}
        <div id="tab1" className={this.state.tab===0 ? '' : 'd-none' } >
          <Container>
            <Row>
              <Col lg="12" className='mb-5'>
                <ul className="steps mt-5">
                  <li className="active col-4">
                    <span>Billing information</span>
                  </li>
                  <li className="disabled col-4">
                    <span>Order Confirmation</span>
                  </li>
                  <li className="disabled col-4">
                    <span>Done</span>
                  </li>
                </ul>
              </Col>
              <Col lg='12'>
                <Row>
                  <Col lg="6">
                    <div className="card rounded-1-5 elevate mb-5">
                      <div className='card-body p-1-5'>
                        <Form.Group>
                          <h1 className='fnt-800'>
                          Please fill-in your billing information
                          </h1>
                          <p className="mb-0 fnt-300">
                            Please submit accurate data. The information below will be used to create your invoice.
                          </p>
                        </Form.Group>
                        <Row>
                          <Col lg='6'>
                            {/* 
                              Start:: add class 'was-validated' in From.Group for Error
                            */}
                            <Form.Group className={this.state.submit && !this.isValid('firstname') ? 'was-validated' : '' } >
                              <Form.Label className='fnt-700'>
                                First Name 
                                <span className="text-danger ml-1">*</span>
                              </Form.Label>
                              <Form.Control 
                                id="firstname" 
                                name="firstname"
                                onChange={this.handleChange}
                                type="text" 
                                className="form-control-v2" placeholder='' 
                                required
                              />
                              {/* Hide element if no error */}
                              <div className={this.state.submit && !this.isValid('firstname') ? 'd-block text-danger small mt-2' : 'd-none' }>
                                First Name is required.
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg='6'>
                            {/* 
                              Start:: add class 'was-validated' in From.Group for Error
                            */}
                            <Form.Group className={this.state.submit && !this.isValid('lastname') ? 'was-validated' : '' } >
                              <Form.Label className='fnt-700'>
                                Last Name 
                                <span className="text-danger ml-1">*</span>
                              </Form.Label>
                              <Form.Control 
								id="lastname"
								name="lastname"
								onChange={this.handleChange}
                                type="text" 
                                className="form-control-v2" placeholder='' 
                                required
                              />
                              {/* Hide element if no error */}
                               <div className={this.state.submit && !this.isValid('lastname') ? 'd-block text-danger small mt-2' : 'd-none' }>
                                Last Name is required.
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* 
                          Start:: add class 'was-validated' in From.Group for Error
                        */}
                        <Form.Group className={this.state.submit && !this.isValid('email') ? 'was-validated' : '' } >
                          <Form.Label className='fnt-700'>
                            Email Address
                            <span className="text-danger ml-1">*</span>
                          </Form.Label>
                          <Form.Control 
							id="email" 
							name="email"
							onChange={this.handleChange}
                            type="email" 
                            className="form-control-v2" placeholder='' 
                            required
                          />
                          {/* Hide element if no error */}
                          <div className={this.state.submit && !this.isValid('email') ? 'd-block text-danger small mt-2' : 'd-none' }>
                            Email address is invalid.
                          </div>
                        </Form.Group>
                        {/* 
                          Start:: add class 'was-validated' in From.Group for Error
                        */}
                        <Form.Group className={this.state.submit && !this.isValid('address') ? 'was-validated' : '' } >
                          <Form.Label className='fnt-700'>
                            Address
                            <span className="text-danger ml-1">*</span>
                          </Form.Label>
                          <Form.Control 
							id="address"
							name="address"
							onChange={this.handleChange}
                            type="text" 
                            className="form-control-v2" placeholder='' 
                            required
                          />
                          {/* Hide element if no error */}
                           <div className={this.state.submit && !this.isValid('address') ? 'd-block text-danger small mt-2' : 'd-none' }>
                            Address is required.
                          </div>
                        </Form.Group>
						<Form.Group className={this.state.submit && !this.isValid('city') ? 'was-validated' : '' } >
                          <Form.Label className='fnt-700'>
                            City
                            <span className="text-danger ml-1">*</span>
                          </Form.Label>
                          <Form.Control 
							id="city"
							name="city"
							onChange={this.handleChange}
                            type="text" 
                            className="form-control-v2" placeholder='' 
                            required
                          />
                          {/* Hide element if no error */}
                           <div className={this.state.submit && !this.isValid('city') ? 'd-block text-danger small mt-2' : 'd-none' }>
                            Address is required.
                          </div>
                        </Form.Group>
                        {/* 
                          Start:: add class 'was-validated' in From.Group for Error
                        */}
                        <Form.Group className=''>
                          <Form.Label className='fnt-700'>
                            Country
                            <span className="text-danger ml-1">*</span>
                          </Form.Label>
                          <Form.Control 
                            as="select"
                            className="custom-select form-control-v2" 
							onChange={this.handleChange}
							id="country"
							name="country"
                            required
                          >
							{this.state.countries.map((country) => (
							  <option key={country.id} value={country.name}>{country.name}</option>
							))}
						  </Form.Control>
                          {/* Hide element if no error */}
                          
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="mb-2 fnt-700 d-block">
                            Do you represent a company?
                          </Form.Label>
                          <div className='mb-3'>
                            <Form.Check 
                              inline
                              type="radio" 
                              name="billing_country_rep" 
                              label="Yes"
							  checked={this.state.billing_country_rep === "1"}
							  onChange={this.handleChange}
							  value="1"
                            />
                            <Form.Check 
                              inline
                              type="radio" 
                              name="billing_country_rep" 
                              label="No"
							  checked={this.state.billing_country_rep === "0"}
							 onChange={this.handleChange}
							 value="0"
                            />
                          </div>
                        </Form.Group>
						
						
                        <Form.Group>
                          <Form.Label className="mb-2 fnt-700 d-block">
                            Ways to contact you directly?
                          </Form.Label>
						  
                          <Form.Check 
                            inline
                            type="radio" 
                            name="social" 
                            id="social" 
                            label="Telegram"
							value="Telegram"
							onChange={this.handleChange}
							checked={this.state.social === "Telegram"}
                          />
                          <Form.Check 
                            inline
                            type="radio" 
                            name="social" 
                            id="social" 
                            label="Linkedin"
							value="Linkedin"
							onChange={this.handleChange}
                          />
                          <Form.Check 
                            inline
                            type="radio" 
                            name="social" 
                            id="social" 
                            label="Discord"
							value="Discord"
							onChange={this.handleChange}
                          />
                          <Form.Check 
                            inline
                            type="radio" 
                            name="social" 
                            id="social" 
                            label="Twitter"
							value="Twitter"
							onChange={this.handleChange}
                          />
                        </Form.Group>
                        
						<Form.Group>
                          <Form.Control 
                          type="text" 
						  className={this.state.social==="Telegram"?'form-control-v2':'d-none'}                          
                          placeholder='URL' 
						  id="billing_telegram"
						  name="billing_telegram"
						  onChange={this.handleChange}
                        />
						<Form.Control 
                          type="text" 
                           className={this.state.social==="Linkedin"?'form-control-v2':'d-none'}              
                          placeholder='URL' 
						  id="billing_linkedin"
						  name="billing_linkedin"
						  onChange={this.handleChange}
                        />
						<Form.Control 
                          type="text" 
                          className={this.state.social==="Discord"?'form-control-v2':'d-none'}              
                          placeholder='URL' 
						  id="billing_discord"
						  name="billing_discord"
						  onChange={this.handleChange}
                        />
						<Form.Control 
                          type="text" 
                          className={this.state.social==="Twitter"?'form-control-v2':'d-none'}       
                          placeholder='URL' 
						  id="billing_twitter"
						  name="billing_twitter"
						  onChange={this.handleChange}
                        />
						<div className={this.state.submit && !this.isValid('social') ? 'd-block text-danger small mt-2' : 'd-none' }>
                                You should have at least 1 valid social profile.
                        </div>
                        </Form.Group>
                        <div className="mb-0">
                          <button onClick={this.setTab} className="btn btn-primary btn-block py-3">
                            Next
                            <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" /></svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="card rounded-1-5 border-secondary">
                      <div className="card-body p-1-5">
                        <h2 className='fnt-800 mb-4'>Order summary</h2>
                        <Row>
                          <Col lg="6">Number of months</Col>
                          <Col lg="6" className='fnt-800 text-lg-right'>
						  {this.state.num_months}
                          </Col>
                        </Row>
						<Row>
                          <Col lg="6">{this.state.domain}</Col>
                          <Col lg="6" className='fnt-800 text-lg-right'>
						  $ {this.state.cost_per_month} / month
                          </Col>
                        </Row>
                      </div>
                      <div className="card-footer py-3">
                        <Row>
                          <Col lg='6' className='fnt-800'>
                          Total
                          </Col>
                          <Col lg='6' className='fnt-800 text-lg-right'>
                          $ {(this.state.cost_per_month * this.state.num_months).toFixed(2)}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        {/* End:: 2nd */}
        {/* Start:: 3rd */}
        <div id="tab2" className={this.state.tab===1 ? '' : 'd-none' } >
          <Container>
            <Row>
              <Col lg="12" className='mb-5'>
                <ul className="steps mt-5">
                  <li className="done col-4">
                    <span>Billing information</span>
                  </li>
                  <li className="active col-4">
                    <span>Order Summary</span>
                  </li>
                  <li className="disabled col-4">
                    <span>Done</span>
                  </li>
                </ul>
              </Col>
              <Col lg='12'>
                <Row>
                  <Col lg="6">
                    <div className="card rounded-1-5 elevate mb-5">
                      <div className='card-body p-1-5'>
                        <div className="mb-3">
                          <h1 className='fnt-800'>
                          Please double check your details below
                          </h1>
                          <p className="mb-0 fnt-300">
                          If everything looks good, please proceed to the next step to submit the payment.
                          </p>
                        </div>
                        <div className="mb-3">
                          <label className="fnt-700">Name</label>
                          <p>
                            <span className="text-capitalize text-secondary">
                            {this.state.firstname+' '+this.state.lastname}
                            </span>
                          </p>
                        </div>
                        <div className="mb-3">
                          <label className="fnt-700">Email Address</label>
                          <p>
                            <span className=" text-secondary">
                             {this.state.email}
                            </span>
                          </p>
                        </div>
                        <div className="mb-3">
                          <label className="fnt-700">Address</label>
                          <p>
                            <span className=" text-secondary">
                            {this.state.address}
                            </span>
                          </p>
                        </div>
                        <div className="mb-3">
                          <label className="fnt-700">Ways to contact you directly</label>
                          <p className='mb-0 text-secondary'>
                            <span className="text-capitalize">
                            {this.state.social} - 
                            </span>
                            <span>{this.state.billing_telegram+this.state.billing_linkedin+this.state.billing_discord+this.state.billing_twitter}</span>
                          </p>
                        </div>
                        <div className="mb-0">
							<button onClick={this.handleSubmit} className="btn btn-primary btn-block py-3">
								{this.state.btnSubmitText}
							</button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="card rounded-1-5 border-secondary">
                      <div className="card-body p-1-5">
                        <h2 className='fnt-800 mb-4'>Order summary</h2>
                        <Row>
                          <Col lg="6">marketingb.com</Col>
                          <Col lg="6" className='fnt-800 text-lg-right'>
                          USD $2,188.00
                          </Col>
                        </Row>
                      </div>
                      <div className="card-footer py-3">
                        <Row>
                          <Col lg='6' className='fnt-800'>
                          Total
                          </Col>
                          <Col lg='6' className='fnt-800 text-lg-right'>
                          USD $2,188.00
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        {/* End:: 3rd */}
        {/* Start:: 4th */}
        <div id="tab2" className={this.state.tab===2 ? 'py-5' : 'd-none' } >
          <Container>
            <Row>
              <Col lg="12">
                <div>
                  <div className="success-message elevate text-center">
                    <div className="box-check-success">
                      <svg className="check-icon text-success" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    </div>
                    <h3 className="fnt-700 text-secondary">
                      Thank you for submitting your offer!
                    </h3>
                    <ul className="list-unstyled small">
                      <li>
                        <svg className="w-6 h-6 mr-2 text-muted" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
                        You will be receiving three (3)-emails from Contrib.
                      </li>
                      <li>
                        <svg className="w-6 h-6 mr-2 text-muted" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
                        Please check your spam box for safe measure.
                      </li>
                    </ul>
                    <p>
                    Contrib is our contribution platform and it allows us to get people to help contribute, make offer or partner with premium world class brands. You could check your Offer submission in your <br /> <b>"My Offers Contrib App".</b>
                    </p>
                    <p>
                      <i className="fas fa-envelope mr-2"></i>
                      Please check your email for your Contrib access.
                    </p>
                    <a href="/" className="">
                      <i className="fas fa-home mr-2"></i>
                      Back
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* End:: 4th */}
      </>
    );
  }
}
 
export default Buy2;