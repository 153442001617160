import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import LoadingState from './LoadingState';
import axios from 'axios'
import Swal from 'sweetalert2'

const initialState = {
	domain:window.Configs.domain,
	amount: 10000,
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	confirmPassword: "",
	country_id: window.Configs.countriesarray[0].country_id,
	country: window.Configs.countriesarray[0].name,
	phone: "",
	message: "",
	amountError: "",
	firstNameError: "",
	lastNameError: "",
	emailError: "",
	passwordError: "",
	confirmPasswordError: "",
	countryError: "",
	phoneError: "",
	messageError: "",
	phoneCode: "",
	contactBy: "",
	contactByError: "",
	isLoading: false,
	newSuccess: true,
	newMsgSuccess: false
}

class BuyForm extends Component{

	state = initialState;	
	
	componentDidMount() {
		var url_string = window.location.href;
		var url = new URL(url_string);
		var offer = url.searchParams.get("offer");
		if(offer!==undefined){
			this.setState({amount : offer});
		}
	}
	handleChange = event => {
		this.setState({
			[event.target.name] : event.target.value
		});
	};
	
	countryChange = event => {
		let val = event.target.value.split('::')
		this.setState({
			[event.target.name] : val[0],
			'country' : event.target.options[event.target.selectedIndex].text,
			'phoneCode' : val[1],
			'phone' : `+${val[1]}`
		});
	}

	contactByChange = event => {
		this.setState({
			'contactBy' : event.target.value
		});
	}

	validate = () => {
	    let amountError = "";
		let firstNameError = "";
		let lastNameError = "";
		let emailError = "";
		let passwordError = "";
		let confirmPasswordError = "";
		let countryError = "";
		let phoneError = "";
		let messageError = "";
		let contactByError = "";
		
	    if(!this.state.amount){
			amountError = "Amount is required.";
	    }
	    else if(this.state.amount <= 9999 ){
			amountError = "Amount is must atleast 10000";
			Swal.fire('Notice', 'Hi!,<br> We are selling this asset not less than $10k but we will check your offer as well.', 'warning');
	    }
	    if (!this.state.firstName) {
	    	firstNameError = "First name is required.";
	    }
	    if (!this.state.lastName) {
	    	lastNameError = "Last name is required.";
	    }
	    if (!this.state.email) {
	    	emailError = "Email is required";
	    }else if(!this.state.email.includes("@")){
	    	emailError = "Invalid Email";
	    }
	    if (!this.state.password) {
	    	passwordError = "Password is required.";
	    }
	    if (!this.state.confirmPassword) {
	    	confirmPasswordError = "Confirm password is required.";
	    }
	    else if(this.state.confirmPassword !== this.state.password){
	    	confirmPasswordError = "Confirm password did not match.";
	    }
	    if (!this.state.country_id) {
	    	countryError = "Country is required.";
	    }
	    if (!this.state.phone) {
	    	phoneError = "Phone is required.";
	    } else {
			if(isNaN(this.state.phone)) {
				phoneError = "Invalid phone number.";
			} else if(this.state.phone.indexOf(this.state.phoneCode) < 0) {
				phoneError = "Invalid phone code.";
			}
		}
	    if (!this.state.contactBy) {
	    	contactByError = "Contact method is required.";
	    }
	    if (!this.state.message) {
	    	messageError = "Message is required.";
	    }
	    if ( 
	    	// amountError || 
	    	firstNameError || 
	    	lastNameError || 
	    	emailError || 
	    	passwordError || 
	    	confirmPasswordError || 
	    	countryError || 
	    	phoneError || 
	    	messageError ||
			contactByError
	    )
	    {
	    	this.setState({ 
	    		amountError, 
	    		firstNameError, 
	    		lastNameError, 
	    		emailError, 
	    		passwordError, 
	    		confirmPasswordError, 
	    		countryError, 
	    		phoneError, 
	    		messageError,
				contactByError
	    	});
	    	return false;
	    }

	    return true;
	};

	loadingHandle = () => {
		this.setState({
			isLoading:true
		});
		setTimeout( ()=> {
			this.setState({
				isLoading:false
			});
			this.onChangeFrom();
		},3000);
	}

	onChangeFrom(){
		this.props.onchangeform(this.state.newSuccess,this.state.newMsgSuccess);
	}
	
	sendOfferEmail(firstname,email,domain){
		this.setState({isLoading:true});
		
		const params = new URLSearchParams();
		params.append('firstname', firstname);
		params.append('email', email);
		params.append('domain', domain);
		params.append('template_name', 'Offer');
		axios.post('https://www.contrib.com/forms/autoresponderEmail', params)
			.then(response => {
				this.setState({isLoading:false});
				this.onChangeFrom();
			});
	}

	handleSubmit = event => {
		event.preventDefault();

		const isValid = this.validate();
		
		if (isValid) {
			this.setState({isLoading:true});
			
			const params = new URLSearchParams();
			params.append('field', 'EmailAddress');
			params.append('value', this.state.email);
			axios.post('https://www.contrib.com/signup/checkexist', params)
				.then(response => {
					this.setState({isLoading:false});
					if(response.data.status){						
						const params = new URLSearchParams();
						params.append('domain', this.state.domain);
						params.append('email', this.state.email);
						params.append('message', this.state.message);
						params.append('amount', this.state.amount);
						params.append('amount', this.state.amount);
						params.append('phone', this.state.phone);
						params.append('contact_by', this.state.contactBy);
						this.setState({isLoading:true});
						axios.post('https://www.contrib.com/forms/save_offer_for_existing_account', params)
							.then(response => {
								this.sendOfferEmail(this.state.firstName,this.state.email,this.state.domain);
							});
					}else{
						const params = new URLSearchParams();
						params.append('domain', this.state.domain);
						params.append('firstname', this.state.firstName);
						params.append('lastname', this.state.lastName);
						params.append('email', this.state.email);
						params.append('country_id', this.state.country_id);
						params.append('country', this.state.country);
						params.append('password', this.state.password);
						params.append('contact', this.state.phone);
						params.append('message', this.state.message);
						params.append('amount', this.state.amount);
						params.append('phone', this.state.phone);
						params.append('contact_by', this.state.contactBy);
						this.setState({isLoading:true});
						axios.post('https://www.contrib.com/forms/save_offer', params)
							.then(response => {
								this.setState({isLoading:false});
								this.sendOfferEmail(this.state.firstName,this.state.email,this.state.domain);
							});
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	};

	render(){
		return(
			<>	
				<h2 className="text-center mt-3">Purchase {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} today</h2>
				<p className="text-center mb-4">Create your Profile to make an offer</p>
				<Row>
					<Col 
						lg={12}
						className={this.state.amountError ? ('was-validated') : null}
					>
						<Form.Group>
							<Form.Label>Amount <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								type="text"
								name="amount"
								value={this.state.amount}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.amountError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.amountError}
									</div>
								) : null
							}
						</Form.Group>
					</Col>
					<Col lg={6} className={this.state.firstNameError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>First Name <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								type="text"
								name="firstName"
								value={this.state.firstName}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.firstNameError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.firstNameError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={6} className={this.state.lastNameError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								type="text"
								name="lastName"
								value={this.state.lastName}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.lastNameError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.lastNameError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={12} className={this.state.emailError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								type="text"
								name="email"
								value={this.state.email}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.emailError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.emailError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={6} className={this.state.passwordError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>Password <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								type="password"
								name="password"
								value={this.state.password}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.passwordError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.passwordError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={6} className={this.state.confirmPasswordError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								type="password"
								name="confirmPassword"
								value={this.state.confirmPassword}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.confirmPasswordError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.confirmPasswordError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={6} className={this.state.countryError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>Choose Country <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								as="select"
								name="country_id"
								value={this.state.country_id+'::'+this.state.phoneCode}
								onChange={this.countryChange}
								required
								className="custom-select"
							>
								<option></option>
								{
									window.Configs.countriesarray.map(
										country=><option key={country.country_id} value={country.country_id+'::'+country.phone_code}>{country.name}</option>
									)
								}
							</Form.Control>
							{
								this.state.countryError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.countryError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={6} className={this.state.phoneError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>Phone <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								type="text"
								name="phone"
								value={this.state.phone}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.phoneError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.phoneError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={12} className={this.state.contactByError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>How do you wish to be contacted? <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								as="select"
								name="contact_by"
								value={this.state.contactBy}
								onChange={this.contactByChange}
								required
								className="custom-select"
							>
								<option value=""></option>
								<option value="SMS">SMS</option>
								<option value="Email">Email</option>
							</Form.Control>
							{
								this.state.contactByError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.contactByError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={12} className={this.state.messageError ? ('was-validated') : null}>
						<Form.Group>
							<Form.Label>Message <span className="text-danger">*</span></Form.Label>
							<Form.Control 
								as="textarea" 
								rows="3" 
								name="message"
								value={this.state.message}
								onChange={this.handleChange}
								required
							/>
							{
								this.state.messageError ? (
									<div className="d-block text-danger small mt-2">
										{this.state.messageError}
									</div>
								):null
							}
						</Form.Group>
					</Col>
					<Col lg={12}>
						<Form.Group className="mt-2">
							<Button variant="primary" className="btn-block py-3" onClick={this.handleSubmit}>
								Submit
							</Button>
						</Form.Group>
					</Col>
					<Col lg={12}>
						<div className="text-center">
							<Link to="/partner" className="">Or you can partner with this domain!</Link>
						</div>
					</Col>
				</Row>
				{this.state.isLoading ? <LoadingState />: null}
			</>
		);
	}
}

export default BuyForm;