import React, { Component } from 'react';
class Apps extends Component{
	componentDidMount() {
		console.log('invest componentDidMount');
		if (typeof window.ReloadPage === 'function') { console.log('fire ReloadPage'); window.ReloadPage();}
	}
	render(){
		return(
			<React.Fragment>
				<style>
				{`
				.navbar-dark {
						display: none !important;
					}
					.footer-dark-1 {
						display: none !important;
					}
					.footer-dark-2 {
						display: none !important;
					}
				.public-pages {
					min-height: calc(100vh - 50px);
					background-position: 50%;
					background-size: cover;
					background-repeat: no-repeat;
					padding-top: 40px;
					padding-bottom: 100px;
					position: relative;
					}
					.overlay {
					background-color: rgba(0, 0, 0, 0.85);
					}
					.jumbotron {
					background: none;
					color: #ffffff;
					padding: 2rem;
					}	
					.box-app {
					display: flex;
					height: 100%;
					flex-direction: column;
					border-radius: 8px;
					background: #fff;
					}
				`}
				</style>				
				<div className="apps-script"></div>
			</React.Fragment>
		);
	}
}

export default Apps;